body {
  margin: 0;
    font-family: Helvetica, Sans-Serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

*{
  box-sizing:border-box;
}
.clock{
  background-color: #000;
  margin: 0 auto;
  width: fit-content;
}
.clock-con{
  background-color: #000;
  width: 100%;
}
#bthn {
  position: relative!important;
  
  bottom: auto!important;
  left: auto!important;
  
  background-color: #000!important;
  margin: 24px auto;

}

.App{
  padding: 0;
  display:flex; 
  flex-direction:column;
  align-items:center;
   gap: 0px; 
   max-width: 1080px;
    width: 100%;
    margin:0 auto;
}
.title-container{
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}
.title{
  font-size:50px; 
  font-weight: 700;
  padding: 20px;
  width: 100%; 
  background-color: #000;
  display: flex; 
  align-items:center; 
  justify-content:center; 
  color: #fff;
}
.sub-title{
  font-size:22px; 
  font-weight: 700;
  padding: 20px;
  width: 100%; 
  background-color: #fcee21;
  display: flex; 
  align-items:center; 
  justify-content:center; 
  color: #000;
}
.ol{
  flex-direction: column;  
  gap: 10px; 
  font-size:18px;
  font-weight: 400;
  padding:30px 60px; 
  margin:0;  
  width: 100%; 
  background-color:#0071bc;
  display:flex;
  align-items:flex-start; 
  justify-content:center; 
  color: #fff;
}
.upload{
  padding: 50px;
  display:flex ;
  flex-direction:column;
  align-items:center;
  gap: 30px;
  width: 100%;
  margin:0 auto;
}

.download{

  display: inline-block;
  outline: 0;
  border: none;
  cursor: pointer;
  padding: 0 24px;
  border-radius: 50px;
  min-width: 200px;
  height: 50px;
  font-size: 18px;
  background-color: #fcee21;
  font-weight: 500;
  color: #222;
  
}

@media screen and (max-width: 1024px) {

  .App{ 
    padding: 0px;
    display:flex; 
    flex-direction:column;
    align-items:center;
    gap: 0px; 
    max-width: 1080px;
      width: 100%;
      margin:0 auto;
  }
  .title{
    font-size:5vw; 
    font-weight: 700;
    padding: 20px;
    width: 100%; 
    background-color: #000;
    display: flex; 
    align-items:center; 
    justify-content:center; 
    color: #fff;
  }
  .sub-title{
    font-size:4.8vw; 
    font-weight: 400;
    text-align: center;
    width: 100%; 
    background-color: #fcee21;
    display: flex; 
    align-items:center; 
    justify-content:center; 
    color: #000;
    padding: 20px;
  }
  .ol{
    flex-direction: column;  
    gap: 10px; 
    font-size:4vw;
    font-weight: 400;
    line-height: 1.2;
    padding:8vw 12vw; 
    margin:0;  
    width: 100%; 
    background-color:#0071bc;
    display:flex;
    align-items:flex-start; 
    justify-content:center; 
    color: #fff;
  }
  .upload{
    padding: 25px 25px 50px 25px ;
    display:flex ;
    flex-direction:column;
    align-items:center;
    gap: 30px;
    width: 100%;
    margin:0 auto;
  }
}